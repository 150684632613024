import React, { Component }  from 'react';
import ReactPlayer from 'react-player';
import "./CloudTab.css";
export default function CloudTab() {
  return (
   <div className='grid-container'>
      <div className="intro">
        <h1>CloudPotential Data tools</h1>
      </div>
      <p className="Problem">
        Problem: In Academic research and biotech+ other industries B2B customers spend time on learning, using, and
  paying for various subscriptions, not always tailored to science and engineering.
        <br /><br />
       Totaling (n=16 participants) an
  average annual cost of $2321 per individual spent on graphic design, programming, analysis software, and
  communication applications.
      </p>
    <p className="Solution">
        Solution: CloudPotential Data Tools, time saving platform with data analysis tools, pseudo-code translations, Ai
  enhanced styling and schematics, storage, and co-working tools all in one.
        <br /><br />
        <link  href="https://www.cloudpotentialdatatools.com/" />
      </p>
              <div className='wrapper'>
  <ReactPlayer
    className='player'
    playing
    url={'https://youtu.be/mGBJGWnnyCc'}
    width='100%'
    height='100%'
  />
</div>
    </div>
  );
}
