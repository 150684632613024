import ReactPlayer from 'react-player';
import React, { Suspense, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import HeldTab from './HeldTab.js';
import CloudTab from './CloudTab.js';
import Card from 'react-bootstrap/Card';
function OpportunitiesTab() {
  return (
    <>
    <Card border="info">
    <Card.Header></Card.Header>
    <Card.Body>
      <Card.Title>  </Card.Title>
      <Card.Text>
      <CloudTab/>
      </Card.Text>
    </Card.Body>
  </Card>
 <br/>
    
    
        </>
      );
    }
 export default OpportunitiesTab;   
